import { render, staticRenderFns } from "./ActivityButton.vue?vue&type=template&id=b1ab622e&scoped=true"
import script from "./ActivityButton.vue?vue&type=script&lang=js"
export * from "./ActivityButton.vue?vue&type=script&lang=js"
import style0 from "./ActivityButton.vue?vue&type=style&index=0&id=b1ab622e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1ab622e",
  null
  
)

export default component.exports