<template>
  <a class="activity-item-block" :class="{ 'activity-item-block-lt': isLittle }" target="_blank" :href="url">
    <div class="item-top">
      <section class="item-left">
        <div class="title">{{ title }}</div>
        <div class="reward-content" :class="{ 'reward-content-lt': isLittle }">{{ rewardContent }}</div>
        <div class="activity-content">{{ activityContent }}</div>
      </section>
      <section class="item-right">
        <img class="item-right-img" :src="nightModeWebBannerUrl" alt="" />
      </section>
    </div>
    <activity-button classname="absolute-button" />
  </a>
</template>

<script>
import ActivityButton from './ActivityButton.vue';

export default {
  props: {
    title: String,
    rewardContent: String,
    activityContent: String,
    url: String,
    nightModeWebBannerUrl: String,
    isLittle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'activity-button': ActivityButton
  },

  methods: {
    onClickUrl() {
      var newWin = window.open();
      newWin.opener = null;
      newWin.location = this.url;
      newWin.target = '_blank';
    }
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.activity-item-block {
  box-sizing: border-box;
  display: block;
  width: 588px;
  height: 240px;
  padding: 24px;
  border-radius: 24px;
  border: 1px solid @black-border-heavy;
  position: relative;
  cursor: pointer;
  color: #fff;
  .item-top {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .item-left {
    color: @black-text-primary;
    .title {
      .medium-font();
      font-size: 16px;
      margin-bottom: 24px;
    }
    .reward-content {
      font-family: @bold-font;
      font-size: 30px;
      font-weight: 800;
      margin-bottom: 8px;
    }
    .reward-content-lt {
      font-size: 24px;
    }
    .activity-content {
      .regular-font();
      font-size: 14px;
      color: @black-text-tertiary;
    }
  }
  .item-right {
    display: flex;
    align-items: center;
    justify-content: center;
    .item-right-img {
      width: 150px;
      height: 150px;
    }
  }
}
.activity-item-block + .activity-item-block {
  margin-left: 24px;
}
.activity-item-block-lt {
  width: 384px;
  height: 249px;
}
.absolute-button {
  position: absolute;
  left: 24px;
  bottom: 24px;
}

@media screen and (min-width: 1020px) and (max-width: 1199px) {
.activity-item-block {
  box-sizing: border-box;
  display: block;
  width: 458px;
  height: 255px;
  position: relative;
  cursor: pointer;
  color: #fff;
  flex-shrink: 0;
}
}

@media screen and (max-width: 1019px) {
  .activity-item-block {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 311px;
    height: 222px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 24px;

    .item-left {
      .title {
        font-size: 14px;
        margin-bottom: 16px;
      }
      .reward-content {
        font-size: 22px;
        margin-bottom: 4px;
      }
      .reward-content-lt {
        font-size: 12px;
      }
      .activity-content {
        font-size: 12px;
      }
    }
    .item-right {
      align-items: flex-start;
      .item-right-img {
        width: 100px;
        height: 100px;
      }
    }
  }
  .activity-item-block + .activity-item-block {
    margin-left: 16px;
  }
  .activity-item-block-lt {
    width: 311px;
    height: 222px;
  }
  .absolute-button {
    position: absolute;
    left: 16px;
    bottom: 24px;
  }
}
</style>