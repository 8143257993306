<template>
  <div class="popular-activity-block" v-if="isShow">
    <h2 class="popular-title">{{ $t('index.popular_activity') }}</h2>
    <section class="item-row">
      <activity-card v-for="item in activityItems" v-bind="item" :key="item.id" :isLittle="isLittle" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActivityCard from './ActivityCard.vue';
import { getPopularActivity } from '~/server/common/index.js';

export default {
  components: {
    'activity-card': ActivityCard
  },
  data() {
    return {
      activityItems: []
    };
  },
  computed: {
    ...mapState(['locale']),
    isShow() {
      const len = this.activityItems.length;
      return len === 2 || len === 3;
    },
    isLittle() {
      return window.innerWidth > 1020 && this.activityItems.length === 3;
    }
  },
  watch: {
    locale(val) {
      this.getData(val);
    }
  },
  methods: {
    getData(locale) {
      let lang = locale;
      switch (locale) {
        case 'zh-CN':
          lang = 'cn';
          break;
        case 'zh-TW':
          lang = 'tw';
          break;
      }
      getPopularActivity({ lang }).then(res => {
        this.activityItems = [];
        if (res.code === '00000') {
          this.activityItems = res.data || [];
        }
      });
    }
  },
  mounted() {
    this.getData(this.locale);
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.popular-activity-block {
  box-sizing: border-box;
  width: 1200px;
  margin: auto;
  margin-bottom: 64px;
  .popular-title {
    font-size: 40px;
    .bold-font();
    color: @text-white-V2;
    margin-bottom: 32px;
  }
  .item-row {
    display: flex;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1199px) {
  div,
  section {
    box-sizing: border-box;
  }
  .popular-activity-block {
    box-sizing: border-box;
    width: 940px;
    margin: auto;
    margin-bottom: 64px;
    .popular-title {
      font-size: 40px;
      .bold-font();
      color: @text-white-V2;
      margin-bottom: 32px;
    }
    .item-row {
      display: flex;
      overflow: auto;
      scrollbar-width: none;
    }
        /* 针对 Chrome 的额外样式 */
    .item-row::-webkit-scrollbar {
      display: none; /* 针对 Chrome, Safari 和 Opera */
    }
  }
}
@media screen and (max-width: 1019px) {
  .popular-activity-block {
    box-sizing: border-box;
    width: auto;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 56px;
    
    .popular-title {
      font-size: 24px;
      margin-bottom: 24px;
      text-align: center;
    }
    .item-row {
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
    }
    /* 针对 Chrome 的额外样式 */
    .item-row::-webkit-scrollbar {
      display: none; /* 针对 Chrome, Safari 和 Opera */
    }
  }
}
</style>