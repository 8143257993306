<template>
  <div class="popular-activity-button" :class="[classname]">
    {{ $t('startPage.oneContent.btn') }}
  </div>
</template>

<script>
export default {
  props: ['classname']
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.popular-activity-button {
  width: 148px;
  height: 40px;
  border-radius: 12px;
  background-color: @black-btn-tertiary-default-V2;
  font-size: 14px;
  .medium-font();
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: @text-other-V2;
  &:hover {
    background-color: @black-btn-tertiary-hover-V2;
  }
}

@media screen and (max-width: 768px) {
  .popular-activity-button {
    box-sizing: border-box;
    width: 279px;
    height: 40px;
    line-height: 40px;
    border-radius: 12px;
    font-size: 14px;
  }
}
</style>